import { createSlice } from "@reduxjs/toolkit";
import {
  reducers,
  extraReducers,
  initialState,
} from "./reducers/lessonReducers";

export const LessonSlice = createSlice({
  name: "lesson",
  initialState,
  reducers,
  extraReducers,
});

export const { setLessonId } = LessonSlice.actions;
export default LessonSlice.reducer;
