import React from "react";
import DesignSystemButton from "../components/DesignSystemButton/DesignSystemButton";

const ToastCloseButton = ({ closeToast }) => {
  return (
    <DesignSystemButton icon="close" onClick={closeToast} type="tertiary" />
  );
};

export default ToastCloseButton;
