import { actionLoadingStates } from "./stateEnums";

export const isLoading = (loadingState) => {
  return loadingState === actionLoadingStates.pending;
};

export const hasLoaded = (loadingState) => {
  return [actionLoadingStates.succeeded, actionLoadingStates.failed].includes(
    loadingState,
  );
};
