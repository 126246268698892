export const headers = (accessToken) => {
  let headersHash;
  if (accessToken) {
    headersHash = { Accept: "application/json", Authorization: accessToken };
  } else {
    headersHash = { Accept: "application/json" };
  }
  return { headers: headersHash };
};

export const host = process.env.REACT_APP_API_ENDPOINT;
