import React, { useEffect, useState } from "react";
import {
  ApolloLink,
  ApolloProvider,
  ApolloClient,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { SentryLink } from "apollo-link-sentry";
import { useAuth } from "react-oidc-context";
import apolloCache from "./apolloCache";

const CustomApolloProvider = ({ children }) => {
  const auth = useAuth();
  const [accessToken, setAccessToken] = useState(auth.user?.access_token);

  useEffect(() => {
    setAccessToken(auth.user?.access_token || "");
  }, [auth.user?.access_token]);

  const apiEndpointLink = createHttpLink({
    uri: process.env.REACT_APP_API_ENDPOINT + "/graphql",
  });

  const apiAuthLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      Authorization: accessToken,
    },
  }));

  const client = new ApolloClient({
    link: ApolloLink.from([new SentryLink(), apiAuthLink, apiEndpointLink]),
    cache: apolloCache,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default CustomApolloProvider;
