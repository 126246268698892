import axios from "axios";

import { headers, host } from "./shared";

export const createSchool = async (school, accessToken) => {
  return await axios.post(
    `${host}/api/schools`,
    { school },
    headers(accessToken),
  );
};

export const getSchool = async (schoolId, accessToken) => {
  const response = await axios.get(
    `${host}/api/schools/${schoolId}`,
    headers(accessToken),
  );
  return response.data;
};

export const getUserSchool = async (accessToken) => {
  let response;
  try {
    response = await axios.get(`${host}/api/school`, headers(accessToken));
    return response.data;
  } catch (error) {
    if (error.response?.status === 404) {
      return;
    } else {
      throw error;
    }
  }
};

export const getSchoolMembers = async (schoolId, accessToken) => {
  return await axios.get(
    `${host}/api/schools/${schoolId}/members`,
    headers(accessToken),
  );
};

export const getSchoolStudents = async (schoolId, accessToken) => {
  return await axios.get(
    `${host}/api/schools/${schoolId}/students`,
    headers(accessToken),
  );
};

export const inviteTeacherToSchool = async (
  schoolId,
  teacherEmail,
  accessToken,
) => {
  return await axios.post(
    `${host}/api/schools/${schoolId}/teachers`,
    { email_address: teacherEmail },
    headers(accessToken),
  );
};
