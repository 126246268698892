import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUserJob, getUserJobs } from "../../utils/apiCallHandler";
import { actionLoadingStates } from "../../utils/stateEnums";

export const initialState = {
  loading: actionLoadingStates.idle,
  latestJobInfo: {
    jobId: null,
    studentCount: null,
  },
  details: null,
};

export const loadUserJobs = createAsyncThunk(
  `userJobs`,
  async ({ accessToken, jobId }) => {
    if (jobId) {
      return await getUserJob(accessToken, jobId);
    } else {
      return await getUserJobs(accessToken);
    }
  },
);

export const extraReducers = (builder) => {
  builder.addCase("userJobs/pending", (state) => {
    return { ...state, details: null, loading: actionLoadingStates.pending };
  });
  builder.addCase("userJobs/rejected", (state, action) => {
    return {
      ...state,
      details: null,
      error: action.error,
      loading: actionLoadingStates.failed,
    };
  });
  builder.addCase("userJobs/fulfilled", (state, action) => {
    return {
      ...state,
      details: action.payload?.job,
      loading: actionLoadingStates.succeeded,
    };
  });
};

export const setLatestJobInfo = (state, action) => {
  return {
    ...state,
    latestJobInfo: { ...action.payload },
  };
};

export const resetLatestJobInfo = (state) => ({
  ...state,
  latestJobInfo: { ...initialState.latestJobInfo },
});

export const reducers = {
  setLatestJobInfo,
  resetLatestJobInfo,
};
