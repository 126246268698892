import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import { SettingsContext } from "./settings";
import { startTransition } from "react";

const SettingsProvider = ({ id, children }) => {
  const [cookies, setCookie] = useCookies(["theme", "fontSize"]);
  const themeDefault = window.matchMedia("(prefers-color-scheme:dark)").matches
    ? "dark"
    : "light";
  const location = useLocation();
  // Only use theming if the location pathname matches /{locale}/projects/{identifier} or /{locale}/school/project
  const locale = "[a-z]{2}(-[A-Z]{2})?";
  const useTheming = location.pathname.match(
    new RegExp(`/${locale}/projects/[a-z0-9-]+|/${locale}/school/project`),
  );
  const theme = useTheming ? cookies.theme || themeDefault : "light";

  document.addEventListener("editor-themeUpdated", (e) => {
    startTransition(() => {
      setCookie("theme", e.detail, { path: "/" });
    });
  });

  return (
    <div id={id} className={`--${theme}`}>
      <SettingsContext.Provider
        value={{
          theme,
          fontSize: cookies.fontSize || "small",
        }}
      >
        {children}
      </SettingsContext.Provider>
    </div>
  );
};

export default SettingsProvider;
