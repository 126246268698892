import axios from "axios";

import { errorTypes } from "../stateEnums";
import { isPasswordEncrypted } from "../crypto";
import { headers, host } from "./shared";

export const createStudent = async (student, schoolId, accessToken) => {
  try {
    if (!isPasswordEncrypted(student.password)) {
      throw new Error("Password must be encrypted");
    }

    return await axios.post(
      `${host}/api/schools/${schoolId}/students`,
      { school_student: student },
      headers(accessToken),
    );
  } catch (error) {
    const errorMessage = error.response.data?.error;
    throw errorMessage ? new Error(errorMessage) : error;
  }
};

export const createStudents = async (students, schoolId, accessToken) => {
  try {
    if (
      !students.every(
        (student) =>
          !student?.password || isPasswordEncrypted(student.password),
      )
    ) {
      throw new Error("All passwords must be encrypted");
    }

    return await axios.post(
      `${host}/api/schools/${schoolId}/students/batch`,
      { school_students: students },
      headers(accessToken),
    );
  } catch (error) {
    const errorData = error.response.data?.error;
    const errorType = error.response.data?.error_type;

    // If the error is an object stringify it, else pass back as is. This is to ensure that the error message is always a string
    // and we don't stringify a string. Axios will strip out any custom properties which is annoying...ask me how I know.
    if (errorType === "validation_error") {
      throw JSON.stringify(errorData);
    } else if (errorTypes[errorType]) {
      // We only need to know the type of the error, not the message
      throw errorTypes[errorType];
    } else {
      throw new Error("An unknown error occurred");
    }
  }
};

export const putStudentDetails = async (
  student,
  studentId,
  schoolId,
  accessToken,
) => {
  let response;
  try {
    if (student.password && !isPasswordEncrypted(student.password)) {
      throw new Error("Password must be encrypted");
    }

    response = await axios.put(
      `${host}/api/schools/${schoolId}/students/${studentId}`,
      { school_student: student },
      headers(accessToken),
    );
    return response.data;
  } catch (error) {
    const errorMessage = error.response.data?.error;
    throw errorMessage ? new Error(errorMessage) : error;
  }
};
