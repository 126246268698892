import { WebStorageStateStore } from "oidc-client-ts";

const host = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? `:${window.location.port}` : ""
}`;

const onSigninCallback = (_user) => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

export const oidcConfig = (userType = "full", schoolCode) => {
  const studentUser = userType === "student";
  let scope = "openid email profile force-consent allow-u13-login roles";
  let loginOptions = "v1_signup";
  if (studentUser) {
    scope += " student";
    loginOptions = schoolCode
      ? `v1_signup,school_code=${schoolCode}`
      : "v1_signup";
  }
  return {
    client_id: process.env.REACT_APP_AUTHENTICATION_CLIENT_ID,
    redirect_uri: `${host}/auth/callback`,
    post_logout_redirect_uri: host,
    response_type: "code",
    scope,
    authority: process.env.REACT_APP_AUTHENTICATION_URL,
    silent_redirect_uri: `${host}/auth/silent_renew`,
    automaticSilentRenew: true,
    onSigninCallback,
    filterProtocolClaims: false,
    loadUserInfo: false,
    monitorSession: true,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    extraQueryParams: {
      brand: "editor",
      login_options: loginOptions,
    },
  };
};

export default oidcConfig;
