export const actionLoadingStates = {
  idle: "idle",
  pending: "pending",
  succeeded: "succeeded",
  failed: "failed",
};

export const alertTypes = {
  information: "information",
  success: "success",
  error: "error",
};

export const jobStatuses = {
  information: ["queued", "running", "scheduled"],
  success: "succeeded",
  error: ["retrying", "discarded"],
};

export const errorTypes = {
  validation_error: "validation_error",
  job_concurrency_error: "job_concurrency_error",
  standard_error: "standard_error",
};
