import { createSlice } from "@reduxjs/toolkit";
import {
  reducers,
  extraReducers,
  initialState,
} from "./reducers/userJobReducers";

export const UserJobSlice = createSlice({
  name: "userJob",
  initialState,
  reducers,
  extraReducers,
});

export const { setLatestJobInfo, resetLatestJobInfo } = UserJobSlice.actions;

export default UserJobSlice.reducer;
