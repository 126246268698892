import React from "react";
import { createRoot } from "react-dom/client";
import { PersistGate } from "redux-persist/integration/react";

import "./assets/stylesheets/index.scss";
import "./utils/sentry";
import App from "./App";
import "./utils/i18n";

import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { CookiesProvider } from "react-cookie";

const supportsContainerQueries = "container" in document.documentElement.style;
if (!supportsContainerQueries) {
  // eslint-disable-next-line no-unused-expressions
  import("container-query-polyfill");
}

const div = document.getElementById("root");
const root = createRoot(div);
root.render(
  <CookiesProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </CookiesProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
