import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createClass,
  putClass,
  createStudent,
  createStudents,
  getClasses,
  getSchool,
  getSchoolMembers,
  getSchoolStudents,
  getUserSchool,
  deleteStudentFromClass,
  putStudentDetails,
} from "../../utils/apiCallHandler";
import { actionLoadingStates } from "../../utils/stateEnums";

export const initialState = {
  loading: actionLoadingStates.idle,
  justLoaded: false,
};

export const loadSchool = createAsyncThunk(
  `school/load`,
  async ({ id, accessToken }) => {
    if (id) {
      return await getSchool(id, accessToken);
    } else {
      return await getUserSchool(accessToken);
    }
  },
);

export const createNewStudent = createAsyncThunk(
  `school/createStudent`,
  async ({ student, schoolId, accessToken }) => {
    const response = await createStudent(student, schoolId, accessToken);
    return response?.data;
  },
);

export const createNewStudents = createAsyncThunk(
  `school/createStudents`,
  async ({ students, schoolId, accessToken }) => {
    const response = await createStudents(students, schoolId, accessToken);
    return response?.data;
  },
);

export const createNewClass = createAsyncThunk(
  `school/createClass`,
  async ({ schoolClass, schoolId, accessToken }) => {
    const response = await createClass(schoolClass, schoolId, accessToken);
    return response?.data;
  },
);

export const updateClass = createAsyncThunk(
  `school/updateClass`,
  async ({ schoolClass, schoolId, classId, accessToken }) => {
    const response = await putClass(
      schoolClass,
      schoolId,
      classId,
      accessToken,
    );
    return response?.data;
  },
);

export const loadClasses = createAsyncThunk(
  `school/loadClasses`,
  async ({ schoolId, accessToken }) => {
    const response = await getClasses(schoolId, accessToken);
    return response?.data;
  },
);

export const loadSchoolMembers = createAsyncThunk(
  `school/loadMembers`,
  async ({ schoolId, accessToken }) => {
    const response = await getSchoolMembers(schoolId, accessToken);
    return response?.data;
  },
);

export const loadSchoolStudents = createAsyncThunk(
  `school/loadStudents`,
  async ({ schoolId, accessToken }) => {
    const response = await getSchoolStudents(schoolId, accessToken);
    return response?.data;
  },
);

export const updateStudentDetails = createAsyncThunk(
  `school/updateStudentDetails`,
  async ({ student, studentId, schoolId, accessToken }) => {
    const response = await putStudentDetails(
      student,
      studentId,
      schoolId,
      accessToken,
    );
    return response?.data;
  },
);

export const removeStudentFromClass = createAsyncThunk(
  `school/removeStudentFromClass`,
  async ({ studentId, classId, schoolId, accessToken }) => {
    const response = await deleteStudentFromClass(
      studentId,
      classId,
      schoolId,
      accessToken,
    );

    return response?.data;
  },
);

export const extraReducers = (builder) => {
  builder.addCase("school/load/pending", (state) => {
    return { ...state, loading: "pending" };
  });
  builder.addCase("school/load/fulfilled", (state, action) => {
    return {
      ...state,
      ...action.payload,
      loading: "succeeded",
      justLoaded: true,
    };
  });
  builder.addCase("school/load/rejected", (state, action) => {
    return { ...state, error: action.error, loading: "failed" };
  });
  builder.addCase("school/loadClasses/fulfilled", (state, action) => {
    state.classes = action.payload;
  });
  builder.addCase("school/loadMembers/fulfilled", (state, action) => {
    state.members = action.payload;
  });
  builder.addCase("school/loadStudents/fulfilled", (state, action) => {
    state.members = action.payload;
  });
  builder.addCase("school/createStudents/fulfilled", (state, action) => {
    state.jobId = action.payload;
  });
};

const setSchoolCode = (state, action) => {
  state.code = action.payload;
};

/**
 * Why do we have this? RE: pre-filling login links e.g.
 * http://localhost:3012/auth/user_login/student?schoolCode=12-12-12-12
 *
 * If we use setSchoolCode to store the URL param in `school.code` (which makes logical sense),
 * it will cause a secondary re-render whenever useSchool() hook is used, as we need the
 * school code in App.jsx, which causes the whole app to re-render.
 * Only re-rendering based on autofillSchoolCode prevents useSchool causing secondary render
 */
const setAutofillSchoolCode = (state, action) => {
  state.autofillSchoolCode = action.payload;
};

export const reducers = {
  setSchoolCode,
  setAutofillSchoolCode,
};
