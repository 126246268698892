import React, { lazy, Suspense } from "react";
import { Route, Routes, Navigate, useParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { withAuthenticationRequired } from "react-oidc-context";

const authKey = `oidc.user:${process.env.REACT_APP_AUTHENTICATION_URL}:${process.env.REACT_APP_AUTHENTICATION_CLIENT_ID}`;

const Callback = lazy(() =>
  import(/* webpackPrefetch: true */ "../containers/Callback"),
);
const UserLogin = lazy(() =>
  import(/* webpackPrefetch: true */ "../containers/UserLogin"),
);
const SilentRenew = lazy(() =>
  import(/* webpackPrefetch: true */ "./SilentRenew/SilentRenew"),
);
const GlobalLayout = lazy(() =>
  import(/* webpackPrefetch: true */ "./GlobalLayout/GlobalLayout"),
);
const LandingPage = lazy(() =>
  import(/* webpackPrefetch: true */ "./LandingPage/LandingPage"),
);

const EducationLandingPage = lazy(() =>
  import(
    /* webpackPrefetch: true */ "./EducationLandingPage/EducationLandingPage"
  ),
);
const ProjectLayout = lazy(() =>
  import(/* webpackPrefetch: true */ "./ProjectLayout/ProjectLayout"),
);
const ProjectIndex = lazy(() =>
  import(/* webpackPrefetch: true */ "./ProjectIndex/ProjectIndex"),
);
const ProjectComponentLoader = lazy(() =>
  import(/* webpackPrefetch: true */ "../containers/ProjectComponentLoader"),
);
const EmbeddedViewer = lazy(() =>
  import(/* webpackPrefetch: true */ "./EmbeddedViewer/EmbeddedViewer"),
);

const SchoolOnboarding = lazy(() =>
  import(/* webpackPrefetch: true */ "./SchoolOnboarding/SchoolOnboarding"),
);
const SchoolDashboard = lazy(() =>
  import(/* webpackPrefetch: true */ "./School/SchoolDashboard"),
);

const SchoolLayout = lazy(() =>
  import(/* webpackPrefetch: true */ "./SchoolLayout/SchoolLayout"),
);

const MembersPage = lazy(() =>
  import(/* webpackPrefetch: true */ "./MembersPage/MembersPage"),
);

const ClassLayout = lazy(() =>
  import(/* webpackPrefetch: true */ "./ClassLayout/ClassLayout"),
);

const ClassPage = lazy(() =>
  import(/* webpackPrefetch: true */ "./ClassPage/ClassPage"),
);

const ClassMembersPage = lazy(() =>
  import(/* webpackPrefetch: true */ "./ClassMembersPage/ClassMembersPage"),
);

const LessonPage = lazy(() =>
  import(/* webpackPrefetch: true */ "./LessonPage/LessonPage"),
);

const ProjectPage = lazy(() =>
  import(/* webpackPrefetch: true */ "./ProjectPage/ProjectPage"),
);

const InvitationStart = lazy(() =>
  import(/* webpackPrefetch: true */ "./Invitation/Start"),
);

const InvitationResponsibilities = lazy(() =>
  import(/* webpackPrefetch: true */ "./Invitation/Responsibilities"),
);

const InvitationConfirmation = lazy(() =>
  import(/* webpackPrefetch: true */ "./Invitation/Confirmation"),
);

const suspense = (comp) => <Suspense fallback={<></>}>{comp}</Suspense>;

const projectLinkRedirects = [
  "/null/projects/:identifier",
  "/projects/:identifier",
];
const localeRedirects = ["/", "/projects"];

const ProjectsRedirect = () => {
  const { identifier } = useParams();
  return <Navigate replace to={`/en/projects/${identifier}`} />;
};

const PrivateRoute = (Component) => {
  const PrivateComponent = withAuthenticationRequired(Component);
  return <PrivateComponent />;
};

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const AppRoutes = () => (
  <SentryRoutes>
    <Route path="auth">
      <Route path="callback" element={suspense(<Callback />)} />
      <Route path="silent_renew" element={suspense(<SilentRenew />)} />
      <Route path="user_login/:userType" element={suspense(<UserLogin />)} />
    </Route>

    <Route path={":locale"} element={suspense(<GlobalLayout />)}>
      <Route index element={suspense(<LandingPage />)} />
      <Route path={"projects"} element={suspense(<ProjectLayout />)}>
        <Route index element={suspense(PrivateRoute(ProjectIndex))} />
        <Route
          path={":identifier"}
          element={suspense(<ProjectComponentLoader authKey={authKey} />)}
        />
      </Route>

      <Route
        path="embed/viewer/:identifier"
        element={suspense(<EmbeddedViewer authKey={authKey} />)}
      />
      <Route path="education" element={suspense(<EducationLandingPage />)} />
      <Route
        path="schools/new"
        element={suspense(PrivateRoute(SchoolOnboarding))}
      />
      <Route
        path="invitations/:token"
        element={suspense(<InvitationStart />)}
      />
      <Route
        path="invitations/:token/responsibilities"
        element={suspense(<InvitationResponsibilities />)}
      />
      <Route
        path="invitations/:token/confirmation"
        element={suspense(<InvitationConfirmation />)}
      />

      <Route path="school" element={suspense(PrivateRoute(SchoolLayout))}>
        <Route index element={suspense(<SchoolDashboard />)} />
        <Route path="members" element={suspense(<MembersPage />)} />
        <Route path="class" element={suspense(<ClassLayout />)}>
          <Route index element={suspense(<ClassPage />)} />
          <Route path="members" element={suspense(<ClassMembersPage />)} />
        </Route>
        <Route path="lesson" element={suspense(<LessonPage />)} />
        <Route
          path="project"
          element={suspense(<ProjectPage authKey={authKey} />)}
        />
      </Route>
    </Route>

    {projectLinkRedirects.map((link) => {
      return <Route key={link} path={link} element={<ProjectsRedirect />} />;
    })}

    {localeRedirects.map((link) => {
      return (
        <Route
          key={link}
          path={link}
          element={<Navigate replace to={`/en${link}`} />}
        />
      );
    })}
  </SentryRoutes>
);

export default AppRoutes;
