import axios from "axios";

import { headers, host } from "./shared";

export const getUserJobs = async (accessToken) => {
  const response = await axios.get(
    `${host}/api/user_jobs`,
    headers(accessToken),
  );
  return response?.data;
};

export const getUserJob = async (accessToken, jobId) => {
  const response = await axios.get(
    `${host}/api/user_jobs/${jobId}`,
    headers(accessToken),
  );
  return response?.data;
};
