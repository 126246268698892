import React, { useContext } from "react";
import { useAuth } from "react-oidc-context";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { SettingsContext } from "../../utils/settings";
import { logout } from "../../utils/logout";
import { RpfGlobalNav } from "@raspberrypifoundation/rpf-global-nav/dist";

import "../../assets/stylesheets/GlobalNav.scss";

const GlobalNav = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const settings = useContext(SettingsContext);
  const userType = useSelector((state) => state.editor.userType);

  const onLogIn = () => {
    localStorage.setItem("location", window.location.pathname);
    navigate("/auth/user_login/full");
  };

  const onLogOut = async () => {
    logout(auth);
  };

  const onSignUp = () => {
    window.location.href = `${process.env.REACT_APP_IDENTITY_URL}/signup`;
  };

  return (
    <RpfGlobalNav
      loggedIn={!!auth.user}
      onLogIn={onLogIn}
      onLogOut={onLogOut}
      onSignUp={onSignUp}
      theme={settings.theme}
      hideAccountDropdown={userType !== "full"}
    />
  );
};

export default GlobalNav;
